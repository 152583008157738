<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <esmp-select
        v-if="!isSearch"
        v-model="localValue"
        :placeholder="localPlaceholder"
        clearable
        filterable
        :loading="isLoading"
        :disabled="isDisabled"
        :multiple="multipleValues"
        @on-open-change="onFocus"
      >
        <esmp-select-option
          v-for="item in resultItems"
          :key="`cmdb-ke-${item.id}`"
          :value="item.id"
        >
          {{ item.label }}
        </esmp-select-option>
      </esmp-select>

      <esmp-select
        v-if="isSearch"
        v-model="localValue"
        :placeholder="localPlaceholder"
        clearable
        filterable
        :loading="isLoading"
        :disabled="isDisabled"
        :multiple="multipleValues"
        @on-open-change="onFocus"
        :remote-method="debouncedRemoteMethod"
        @on-query-change="setQuery"
      >
        <esmp-select-option
          v-for="item in resultItems"
          :key="`cmdb-ke-${item.id}`"
          :value="item.id"
        >
          {{ item.label }}
        </esmp-select-option>
      </esmp-select>

      <div v-if="v.errors.length || hint" class="hm-form__element-hint">
        {{ v.errors[0] || hint }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import intersectionBy from 'lodash/intersectionBy';
import Hub from '@/plugins/event-hub';
import { getObjectDeepByValue, getValueDeepByKey } from '@/utils/findDeep';
import uid from '@/components/hm-form/mixins/uid';
import debounce from 'lodash/debounce';
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'HmCmdb',
  mixins: [uid],
  inject: ['fields'],
  props: {
    /**
     * Объект с выбранными значениями контроллов в форме
     */
    model: {
      type: Object,
      required: true,
    },

    value: {
      type: [Object, String, Array],
      default: null,
    },

    cmdbId: {
      type: String,
      required: true,
    },

    cmdbClassName: {
      type: String,
      required: true,
    },

    parentCmdbId: {
      type: String,
      default: null,
    },

    items: {
      type: Array,
      required: true,
    },

    name: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    rules: {
      type: [String, Object],
      default: '',
    },

    cmdbDefaultValueId: {
      type: String,
      default: undefined,
    },

    isSearch: {
      type: Boolean,
      default: false,
    },

    multipleValues: {
      type: Boolean,
      default: false,
    },

    attributeNameAsItemName: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      isLoading: false,
      localItems: null, // загруженные КЕ, в случае, если у cmdb есть родитель
      isDataAlreadyLoaded: false, // предотвращение повторных запросов при фокусе на селект
      parentCmdbValue: null, // выбранное значение в родительском cmdb
      query: null, // поисковый запрос для получения списка КЕ, если параметр isSearch = true
    };
  },

  computed: {
    localPlaceholder() {
      return `${this.placeholder} ${(this.required ? ' *' : '')}`;
    },

    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
        Hub.$emit('hm-cmdb-changed', { cmdbId: this.cmdbId, value });
      },
    },

    localValueObject() {
      const localId = this.isSearch ? this.localValue?.id : this.localValue;
      return this.resultItems.find((item) => item.id === localId) || null;
    },

    hint() {
      return this.localValueObject?.info || null;
    },

    resultItems() {
      return this.localItems || this.items;
    },

    hasParentCmdb() {
      return !!this.parentCmdbId;
    },

    isDisabled() {
      return this.hasParentCmdb && !this.parentCmdbValue;
    },
  },

  created() {
    Hub.$on('hm-cmdb-changed', this.onOtherCmdbChanged);

    if (this.isSearch) {
      this.debouncedRemoteMethod = debounce(this.remoteMethod, SEARCH_DELAY);
      this.field = getObjectDeepByValue(this.fields, this.cmdbId); // ссылка на элемент из fields в HmFormFields
      this.field.searchedItems = [];
    } else {
      if (this.parentCmdbId) this.parentCmdbValue = getValueDeepByKey(this.model, this.parentCmdbId);

      // Если есть значение по умолчанию, но еще до этого не был выбрано значение
      if (this.cmdbDefaultValueId && !this.value) {
        this.localValue = this.cmdbDefaultValueId;
      } else if (this.value) {
        /*
          Если есть уже есть выбранное значение, то нужно переинициализировать
          модель вызовом события input для того, чтобы значение не потерялось.

          Обычно такое происходит при переключении групп туда-сюда.
         */
        this.localValue = this.value;
      }
    }
  },

  destroyed() {
    Hub.$off('hm-cmdb-changed', this.onOtherCmdbChanged);
  },

  methods: {
    async onOtherCmdbChanged({ cmdbId: changedCmdbId }) {
      if (String(changedCmdbId) === String(this.parentCmdbId)) {
        await this.$nextTick();

        this.reset();

        await this.$nextTick();

        this.parentCmdbValue = getValueDeepByKey(this.model, this.parentCmdbId);
      }
    },

    reset() {
      this.localItems = null;
      // очистка значения, если оно существует в модели
      if (getValueDeepByKey(this.model, this.cmdbId)) {
        this.localValue = null;
      }
      this.isDataAlreadyLoaded = false;
      this.isLoading = false;
      this.parentCmdbValue = null;

      if (this.isSearch) {
        delete this.field.searchedItems;
      }
    },

    onFocus(isFocusIn) {
      if (this.hasParentCmdb && isFocusIn) {
        if (this.parentCmdbValue && !this.isDataAlreadyLoaded) {
          this.getConfigItems(this.parentCmdbValue);
        }
      }
    },

    async getConfigItems(parentCmdbValue = null) {
      this.isLoading = true;

      const configItems = await this.$API.cmdb.getConfigItems({
        className: this.cmdbClassName,
        linkedConfigItem: parentCmdbValue,
        query: this.query,
        attributeNameAsItemName: this.attributeNameAsItemName,
      });

      if (this.isSearch) {
        const mappedItems = configItems.map((ke) => ({
          id: ke.itemId,
          label: ke.itemContent,
        }));

        this.localItems = mappedItems;

        // не важно, что возможны дупликаты. Важно, что find найдет первый попавшийся, а массив потом очищается
        this.field.searchedItems.push(...mappedItems);
      } else {
        const filteredIds = configItems.map((ke) => ({ id: ke.itemId }));
        this.localItems = intersectionBy(this.items, filteredIds, 'id');
      }

      this.isDataAlreadyLoaded = true;
      this.isLoading = false;
    },

    remoteMethod(query) {
      if (query !== '' && query.length > 2) {
        this.getConfigItems();
      } else {
        this.localItems = [];
      }
    },

    setQuery(val) {
      this.query = val;
    },
  },
};
</script>
